// System
import React, { useCallback, useState, useRef } from "react";

// Libraries
import AvatarEditor from "react-avatar-editor";

// Icons
import { BsPencilSquare } from "react-icons/bs";

// Modules
import { Formik, FormikHelpers } from "formik";
import { FileRejection } from "react-dropzone";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  AlertColor,
  Autocomplete,
  Avatar,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Slider,
  TextField,
} from "@mui/material";

// API
import {
  changePassword,
  getUserDetails,
  getUserPicture,
  updateUserProfile,
  uploadUserPicture,
} from "../../../api/user";

// Contexts
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

// Custom Hooks
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";

// Components
import {
  CommonCard,
  ContentHeader,
  Dropzone,
  Spinner,
  XSnackbar,
} from "../../../components";

// Utils
import { countryToArray } from "../../../utils/country";
import { industriesToArray, industriesToString } from "../../../utils/industry";
import { stringAvatar } from "../../../utils/avatar";
import {
  disciplinesToArray,
  disciplinesToString,
} from "../../../utils/discipline";

// Schemas
import {
  PasswordForm,
  PasswordInitialValue,
  PasswordSchema,
} from "./consts/password-schemas";
import {
  ProfileForm,
  ProfileInitialValue,
  ProfileSchema,
} from "./consts/profile-schemas";

// Styles
import { outlinedButton } from "../../../@styles/outlinedButton";
import { containedButton } from "../../../@styles/containedButton";

// Types
import { AuthContextType, AuthPropertyType } from "../../../@types/authContext";
import { CountryOptionType } from "../../../@consts/country";
import { DisciplineOptionType } from "../../../@consts/discipline";
import { IndustryOptionType } from "../../../@consts/industry";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";

// Constants
import { ALERT } from "../../../@consts/alert";
import { countryOptions } from "../../../@consts/country";
import { disciplineOptions } from "../../../@consts/discipline";
import { industryOptions } from "../../../@consts/industry";

const filter = createFilterOptions<DisciplineOptionType>();

const Profiles = () => {
  // Contexts
  const { auth, setAuth } = useAuthContext() as AuthContextType;
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Data State
  const [countryValue, setCountryValue] = useState<CountryOptionType | null>(
    null,
  );
  const [industryValue, setIndustryValue] = useState<IndustryOptionType[]>([]);
  const [disciplinesValue, setDisciplinesValue] = useState<
    DisciplineOptionType[]
  >([]);

  // Components state
  const [openEditImage, setOpenEditImage] = useState<boolean>(false);
  const [imageEditorScale, setImageEditorScale] = useState<number>(1);

  // Components Ref
  const imageEditorRef = useRef<any>();

  // Events
  const handleAlert = (alertType: string, alertBody: string) =>
    snackbar(alertType, alertBody);

  const handleValidation = (formIsValid: boolean) => {
    if (!formIsValid) {
      snackbar(ALERT.error, "Validation error");
    }
  };

  const handleCountryDefaultValue = (countryString: string) => {
    if (countryString.length < 1) return;

    const countryArrayValue = countryToArray(countryString);

    setCountryValue(countryArrayValue);
  };

  const handleIndustryDefaultValue = (industriesString: string) => {
    if (industriesString.length < 1) return [];

    const industryArrayValue = industriesToArray(industriesString);

    setIndustryValue(industryArrayValue);
  };

  const handleDisciplineDefaultValue = (disciplineString: string) => {
    if (disciplineString.length < 1) return [];

    const disciplineArrayValue = disciplinesToArray(disciplineString);

    setDisciplinesValue(disciplineArrayValue);
  };

  // Form Submit
  const onDropAvatar = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fd = new FormData();
      fd.append("image", file, file.name);

      updateAvatarMutation.mutate({ userId: auth?.id!, body: fd });
    });
  }, []);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      const errors: string[] = file.errors.map(({ message }) => message);

      const errorMessage: string = `${file.file.name} rejected. ${errors.join(
        ", ",
      )}`;

      snackbar(ALERT.error, errorMessage);
    });
  }, []);

  const updateProfile = (
    values: ProfileForm,
    actions: FormikHelpers<ProfileForm>,
  ) => {
    const body: ProfileForm = {
      id: values.id,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role,
      companyName: values.companyName,
      country: values.country,
      industryGroupSector: values.industryGroupSector,
      disciplines: values.disciplines,
      assessorId: values.assessorId,
    };

    updateProfileMutation.mutate(body);

    actions.setSubmitting(false);
  };

  const updatePassword = (
    values: PasswordForm,
    actions: FormikHelpers<PasswordForm>,
  ) => {
    updatePasswordMutation.mutate({ ...values, id: auth?.id! });

    actions.setSubmitting(false);
  };

  // Mutation
  const updateProfileMutation = useMutation(updateUserProfile, {
    onSuccess(data) {
      const alertType = ALERT.success;
      const alertBody = "User profile succesfully updated";

      handleAlert(alertType, alertBody);

      user.refetch();

      const newAuth: AuthPropertyType = {
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        country: data.data.country,
        role: auth?.role!,
        id: auth?.id!,
        accessToken: auth?.accessToken!,
      };

      setAuth(newAuth);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const updateAvatarMutation = useMutation(uploadUserPicture, {
    onSuccess: () => {
      const alertType = ALERT.success;
      const alertBody = "User picture succesfully updated";

      handleAlert(alertType, alertBody);

      userPicture.refetch();
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const updatePasswordMutation = useMutation(changePassword, {
    onSuccess: () => {
      const alertType = ALERT.success;
      const alertBody = "User password succesfully updated";

      handleAlert(alertType, alertBody);
    },
    onError: (error: ErrorInterface) => {
      const alertType = ALERT.error;
      const alertBody = error.response.data?.message || error.message;

      handleAlert(alertType, alertBody);
    },
  });

  // Data Fetching
  const user = useQuery(["user", auth?.id], () => getUserDetails(auth?.id!), {
    onSuccess: (data) => {
      handleCountryDefaultValue(data.data.country);
      handleIndustryDefaultValue(data.data.industryGroupSector);
      handleDisciplineDefaultValue(data.data.disciplines);
    },
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }
    },
  });

  const userPicture = useQuery(["user-picture", auth?.id], () =>
    getUserPicture(auth?.id!),
  );

  const handleOpenEditImage = () => {
    setOpenEditImage(true);
  };

  const handleCloseEditImage = () => {
    setOpenEditImage(false);
  };

  const handleImageEditorResult = async () => {
    const blob = await getImageEdited();
    const fd = new FormData();
    fd.append("image", blob);
    updateAvatarMutation.mutate({ userId: auth?.id!, body: fd });
    setOpenEditImage(false);
  };

  const handleChangeZoom = (event: Event, newValue: number | number[]) => {
    setImageEditorScale(newValue as number);
  };

  const getImageEdited = async () => {
    const dataUrl = imageEditorRef.current.getImage().toDataURL();
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return blob;
  };

  // JSX
  return (
    <>
      <ContentHeader h1="Profiles" />

      {user.isLoading ? (
        <Spinner marginTop />
      ) : (
        <>
          <CommonCard title="Update profile">
            <div className="flex flex-col gap-10 sm:flex-row">
              <div className="flex-initial basis-1/3">
                <div className="flex w-full flex-col items-center justify-center">
                  <div className="relative cursor-pointer">
                    <div className="flex h-20 w-20 flex-col items-center justify-center text-xl md:h-32 md:w-32 xl:h-48 xl:w-48">
                      <BsPencilSquare />
                      <span>Edit Image</span>
                    </div>
                    <Avatar
                      src={userPicture.data}
                      {...stringAvatar(
                        `${user.data?.data.firstName} ${user.data?.data.lastName}`,
                      )}
                      onClick={handleOpenEditImage}
                      className="absolute top-0 left-0 h-20 w-20 cursor-pointer text-4xl hover:opacity-70 md:h-32 md:w-32 xl:h-48 xl:w-48"
                    />
                  </div>
                  <div>
                    <Dropzone
                      multiple={false}
                      maxSize={2000000}
                      accept={{ "image/*": [] }}
                      onDrop={onDropAvatar}
                      onDropRejected={onDropRejected}
                    >
                      <Button className="btn btn-primary mt-6">Upload</Button>
                    </Dropzone>
                  </div>
                  <Dialog
                    open={openEditImage}
                    onClose={() => setOpenEditImage(!openEditImage)}
                    fullWidth={true}
                    maxWidth={"md"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <h2>Edit Profile Picture</h2>
                    </DialogTitle>
                    <DialogContent
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      dividers={true}
                    >
                      <p className="py-[2px] text-center">
                        Please drag the image and use the zoom slider below to
                        adjust
                      </p>
                      <AvatarEditor
                        ref={imageEditorRef}
                        image={userPicture.data as string}
                        width={200}
                        height={200}
                        border={50}
                        borderRadius={200}
                        color={[255, 255, 255, 0.6]}
                        scale={imageEditorScale}
                        rotate={0}
                      />
                      <Box width={250} marginTop={2}>
                        <span className="text">Zoom</span>
                        <Slider
                          min={0.1}
                          max={2}
                          step={0.01}
                          defaultValue={1}
                          onChange={handleChangeZoom}
                          valueLabelDisplay="auto"
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        onClick={handleCloseEditImage}
                        sx={outlinedButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleImageEditorResult}
                        sx={containedButton}
                      >
                        Apply
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
              <div className="flex-initial basis-2/3">
                {user.isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="flex flex-col">
                    <Formik
                      initialValues={
                        {
                          ...ProfileInitialValue,
                          ...user.data?.data,
                        } as ProfileForm
                      }
                      validationSchema={ProfileSchema}
                      onSubmit={updateProfile}
                      validateOnMount={true}
                    >
                      {(props) => (
                        <form
                          onSubmit={props.handleSubmit}
                          autoComplete="off"
                          className="flex flex-col"
                        >
                          <label htmlFor="userName" className="text">
                            Username<span className="text-orange-alt">*</span>
                          </label>

                          <TextField
                            value={props.values.userName}
                            disabled
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.userName && props.touched.userName
                                ? true
                                : false
                            }
                            helperText={
                              props.errors.userName && props.touched.userName
                                ? props.errors.userName
                                : ""
                            }
                            variant="standard"
                            type="text"
                            name="userName"
                            id="userName"
                            placeholder="Enter unique username"
                            className="mt-4"
                          />
                          <div className="mt-4 flex flex-row justify-between gap-4">
                            <div className="flex w-full flex-col">
                              <label htmlFor="firstName" className="text">
                                First name
                                <span className="text-orange-alt">*</span>
                              </label>
                              <TextField
                                value={props.values.firstName}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                error={
                                  props.errors.firstName &&
                                  props.touched.firstName
                                    ? true
                                    : false
                                }
                                helperText={
                                  props.errors.firstName &&
                                  props.touched.firstName
                                    ? props.errors.firstName
                                    : ""
                                }
                                variant="standard"
                                type="text"
                                name="firstName"
                                id="firstName"
                                placeholder="Your first name"
                                className="mt-4"
                              />
                            </div>
                            <div className="flex w-full flex-col">
                              <label htmlFor="lastName" className="text">
                                Last name
                                <span className="text-orange-alt">*</span>
                              </label>
                              <TextField
                                value={props.values.lastName}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                error={
                                  props.errors.lastName &&
                                  props.touched.lastName
                                    ? true
                                    : false
                                }
                                helperText={
                                  props.errors.lastName &&
                                  props.touched.lastName
                                    ? props.errors.lastName
                                    : ""
                                }
                                variant="standard"
                                type="text"
                                name="lastName"
                                id="lastName"
                                placeholder="Last name"
                                className="mt-4"
                              />
                            </div>
                          </div>
                          <label htmlFor="email" className="text mt-4">
                            Email Address
                            <span className="text-orange-alt">*</span>
                          </label>
                          <TextField
                            disabled
                            value={props.values.email}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.email && props.touched.email
                                ? true
                                : false
                            }
                            helperText={
                              props.errors.email && props.touched.email
                                ? props.errors.email
                                : ""
                            }
                            variant="standard"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter valid email"
                            className="mt-4"
                          />

                          <label htmlFor="assessorId" className="text mt-4">
                            SIRI Assessor ID
                          </label>
                          <TextField
                            disabled={false}
                            value={props.values.assessorId}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.assessorId &&
                              props.touched.assessorId
                                ? true
                                : false
                            }
                            helperText={
                              props.errors.assessorId &&
                              props.touched.assessorId
                                ? props.errors.assessorId
                                : ""
                            }
                            variant="standard"
                            type="text"
                            name="assessorId"
                            id="assessorId"
                            placeholder="Enter your SIRI Assessor ID"
                            className="mt-4"
                          />

                          <label htmlFor="companyName" className="text mt-4">
                            Company name
                            <span className="text-orange-alt">*</span>
                          </label>
                          <TextField
                            value={props.values.companyName}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.companyName &&
                              props.touched.companyName
                                ? true
                                : false
                            }
                            helperText={
                              props.errors.companyName &&
                              props.touched.companyName
                                ? props.errors.companyName
                                : ""
                            }
                            variant="standard"
                            type="text"
                            name="companyName"
                            id="companyName"
                            placeholder="Enter your company name"
                            className="mt-4"
                          />

                          <label htmlFor="country" className="text mt-4">
                            Country<span className="text-orange-alt">*</span>
                          </label>
                          <p className="text-hint">
                            Select your country or region
                          </p>

                          <Autocomplete
                            onChange={(e, option) => {
                              props.setFieldValue("country", option?.label!);

                              setCountryValue(option);
                            }}
                            onBlur={props.handleBlur}
                            id="country"
                            value={countryValue}
                            options={countryOptions}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                {option.label}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                            className="mt-4"
                          />
                          {props.errors.country && props.touched.country && (
                            <FormHelperText error>
                              {props.errors.country}
                            </FormHelperText>
                          )}

                          <label
                            htmlFor="industryGroupSector"
                            className="text mt-4"
                          >
                            Industry group
                            <span className="text-orange-alt">*</span>
                          </label>
                          <p className="text-hint">
                            Select the industry 4.0 categories that best
                            represents your challenge
                          </p>
                          <Autocomplete
                            onChange={(e, arrays) => {
                              props.setFieldValue(
                                "industryGroupSector",
                                industriesToString(arrays),
                              );

                              setIndustryValue(arrays);
                            }}
                            onBlur={props.handleBlur}
                            multiple
                            id="industryGroupSector"
                            value={industryValue}
                            options={industryOptions}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                            className="mt-4"
                          />
                          {props.errors.industryGroupSector &&
                            props.touched.industryGroupSector && (
                              <FormHelperText error>
                                {props.errors.industryGroupSector}
                              </FormHelperText>
                            )}

                          <label htmlFor="disciplines" className="text mt-4">
                            Disciplines
                            <span className="text-orange-alt">*</span>
                          </label>
                          <p className="text-hint">
                            Select the manufacturing sector that best represents
                            your business/domain
                          </p>
                          <Autocomplete
                            id="disciplines"
                            multiple
                            onBlur={props.handleBlur}
                            onChange={(event, arrays) => {
                              props.setFieldValue(
                                "disciplines",
                                disciplinesToString(arrays),
                              );

                              setDisciplinesValue(arrays);
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => inputValue === option.title,
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  title: `Add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            value={disciplinesValue}
                            options={disciplineOptions}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.title;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.title}</li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                            className="mt-4"
                          />
                          {props.errors.disciplines &&
                            props.touched.disciplines && (
                              <FormHelperText error>
                                {props.errors.disciplines}
                              </FormHelperText>
                            )}

                          <div className="mt-8 flex flex-row justify-between ">
                            <Button
                              disabled={props.isSubmitting}
                              type="submit"
                              onClick={() => handleValidation(props.isValid)}
                              className="btn btn-primary"
                            >
                              Update Profile
                            </Button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </CommonCard>

          <CommonCard title="Update password">
            <Formik
              initialValues={PasswordInitialValue}
              validationSchema={PasswordSchema}
              onSubmit={updatePassword}
              validateOnMount={true}
            >
              {(props) => (
                <form
                  onSubmit={props.handleSubmit}
                  autoComplete="off"
                  className="flex flex-col"
                >
                  <label htmlFor="oldPassword" className="text">
                    Current password<span className="text-orange-alt">*</span>
                  </label>
                  <TextField
                    value={props.values.oldPassword}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.oldPassword && props.touched.oldPassword
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.oldPassword && props.touched.oldPassword
                        ? props.errors.oldPassword
                        : ""
                    }
                    variant="standard"
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    placeholder="Enter current password"
                    className="mt-4"
                  />
                  <label htmlFor="newPassword" className="text mt-4">
                    New password<span className="text-orange-alt">*</span>
                  </label>
                  <TextField
                    value={props.values.newPassword}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.newPassword && props.touched.newPassword
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.newPassword && props.touched.newPassword
                        ? props.errors.newPassword
                        : ""
                    }
                    variant="standard"
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    placeholder="Enter new password"
                    className="mt-4"
                  />
                  <label htmlFor="confirmPassword" className="text mt-4">
                    Re-enter new password
                    <span className="text-orange-alt">*</span>
                  </label>
                  <TextField
                    value={props.values.confirmPassword}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.confirmPassword &&
                      props.touched.confirmPassword
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.confirmPassword &&
                      props.touched.confirmPassword
                        ? props.errors.confirmPassword
                        : ""
                    }
                    variant="standard"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Enter new password once again"
                    className="mt-4"
                  />
                  <div className="mt-8">
                    <Button
                      type="submit"
                      disabled={props.isSubmitting}
                      onClick={() => handleValidation(props.isValid)}
                      className="btn btn-primary"
                    >
                      Update password
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </CommonCard>
        </>
      )}

      {openSnackbar && (
        <XSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          alertType={alertType as AlertColor}
          alertBody={alertBody}
        />
      )}
    </>
  );
};

export default Profiles;
