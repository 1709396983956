export interface CountryOptionType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countryOptions: CountryOptionType[] = [
  { code: "SA", label: "Saudi Arabia", phone: "966" },
];
