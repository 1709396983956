import {
  AlertColor,
  Autocomplete,
  Button,
  createFilterOptions,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { Field, FieldArray, Formik, FormikHelpers, getIn } from "formik";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { AiOutlineCloudUpload, AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { HiInformationCircle } from "react-icons/hi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { ALERT } from "../../../@consts/alert";
import {
  disciplineOptions,
  DisciplineOptionType,
} from "../../../@consts/discipline";
import { industryOptions, IndustryOptionType } from "../../../@consts/industry";
import { challengeStates } from "../../../@consts/state";
import { AttachmentInterface } from "../../../@types/attachment";
import { ChallengeInterface } from "../../../@types/challenge";
import { FileInterface } from "../../../@types/file";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";
import {
  createNewChallenge,
  editChallenge,
  getChallengeBanner,
  getChallengeDetails,
} from "../../../api/challenge";
import {
  createNewAttachment,
  createNewFile,
  getAttachments,
  getFile,
  removeAttachment,
} from "../../../api/file";
import {
  CommonCard,
  ContentHeader,
  Dropzone,
  Editor,
  HtmlTooltip,
  Spinner,
  XSnackbar,
} from "../../../components";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import useLogout from "../../../hooks/useLogout";
import useSnackbar from "../../../hooks/useSnackbar";
import {
  disciplinesToArray,
  disciplinesToString,
} from "../../../utils/discipline";
import { formatFileName, getExtension, getFileSize } from "../../../utils/file";
import { industriesToArray, industriesToString } from "../../../utils/industry";
import { slugify } from "../../../utils/slugify";
import {
  ChallengeCriteriaForm,
  ChallengeForm,
  ChallengeInitialValue,
  ChallengeSchema,
} from "./consts/schemas";
import { defaultTAC } from "./consts/tac";

const filter = createFilterOptions<DisciplineOptionType>();

const ErrorMessage = ({ name }: { name: string }) => (
  <FormHelperText error>
    <Field
      name={name}
      render={({ form }: HTMLFormElement) => {
        const error = getIn(form.errors, name);

        const touch = getIn(form.touched, name);

        return touch && error ? error : null;
      }}
    />
  </FormHelperText>
);

const AddChallenge = () => {
  // Libraries
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = new QueryClient();

  // Contexts
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const logout = useLogout();
  const snackbar = useSnackbar();

  // Data State
  const [challenges, setChallenges] = useState<ChallengeInterface>();
  const [imageId, setImageId] = useState("");
  const [industryValue, setIndustryValue] = useState<IndustryOptionType[]>([]);
  const [disciplinesValue, setDisciplinesValue] = useState<
    DisciplineOptionType[]
  >([]);
  const [bannerUrl, setBannerUrl] = useState("");
  const [attachments, setAttachments] = useState<FileInterface[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  // UI State
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  // Events
  const handleAlert = (alertType: string, alertBody: string) =>
    snackbar(alertType, alertBody);

  const handleValidation = (formIsValid: boolean) => {
    if (!formIsValid) {
      snackbar(ALERT.error, "Validation error");
    }
  };

  const onDropBanner = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fd = new FormData();
      fd.append("file", file, file.name);

      uploadeBannerMutation.mutate(fd);

      const url = URL.createObjectURL(file);
      setBannerUrl(url);
    });
  }, []);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      const errors: string[] = file.errors.map(({ message }) => message);

      const errorMessage: string = `${file.file.name} rejected. ${errors.join(
        ", ",
      )}`;

      snackbar(ALERT.error, errorMessage);
    });
  }, []);

  const onDropFile = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const fd = new FormData();
      fd.append("file", file, file.name);

      uploadAttachmentMutation.mutate(fd);
    });
  }, []);

  const removeSelectedAttachment = (id: string) => {
    if (isEditMode) {
      removeAttachmentMutation.mutate(id);
    }

    setAttachments((current) =>
      current.filter((file) => {
        return file.id !== id;
      }),
    );
  };

  const handleSubmitAttachments = (objectId: string) => {
    attachments.map((attachment) => {
      submitAttachmentMutation.mutate({
        objectId,
        objectType: "CHALLENGE",
        fileId: attachment.id,
      });
    });
  };

  const handleIndustryDefaultValue = (industriesString: string) => {
    if (industriesString.length < 1) return [];

    const industryArrayValue = industriesToArray(industriesString);

    setIndustryValue(industryArrayValue);
  };

  const handleDisciplineDefaultValue = (disciplineString: string) => {
    if (disciplineString.length < 1) return [];

    const disciplineArrayValue = disciplinesToArray(disciplineString);

    setDisciplinesValue(disciplineArrayValue);
  };

  const handleAttachmentStateinEditMode = (
    fileId: string,
    attachmentId: string,
  ) => {
    setAttachments((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === fileId) {
          return { ...obj, id: attachmentId };
        }

        return obj;
      });

      return newState;
    });
  };

  // Form Submit
  const handleSubmitForm = (
    values: ChallengeForm,
    actions: FormikHelpers<ChallengeForm>,
  ) => {
    if (activeStep === 0) {
      setActiveStep(1);
    } else {
      const isPercentegeError = criteriaPercentageError(values.criterias);

      if (isPercentegeError) {
        snackbar(ALERT.error, "Total of percentage not equal to 100");
      } else {
        isEditMode ? updateChallenge(values) : submitChallenge(values);
      }
    }

    actions.setSubmitting(false);
  };

  const submitChallenge = (values: ChallengeForm) => {
    submitChallengeMutation.mutate({
      ...values,
      startDate: new Date(),
      titleSlug: slugify(values.title),
      imageId,
    });
  };

  const updateChallenge = (values: ChallengeForm) => {
    updateChallengeMutation.mutate({
      ...values,
      imageId,
      titleSlug: slugify(values.title),
    });
  };

  // Mutation
  const submitChallengeMutation = useMutation(createNewChallenge, {
    onSuccess(data) {
      handleSubmitAttachments(data.data.id);

      navigate("/user/challenges", {
        state: {
          showAlert: true,
          alertType: ALERT.success,
          alertBody: "New challenge added successfully",
        },
        replace: true,
      });
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const updateChallengeMutation = useMutation(editChallenge, {
    onSuccess(data) {
      navigate("/user/challenges", {
        state: {
          showAlert: true,
          alertType: ALERT.success,
          alertBody: "Challenge updated successfully",
        },
        replace: true,
      });
    },
    onError(err: ErrorInterface) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const submitAttachmentMutation = useMutation(createNewAttachment, {
    onSuccess(data, variables, context) {
      if (isEditMode) {
        handleAttachmentStateinEditMode(variables.fileId, data.data.id);

        const alertType = ALERT.success;
        const alertBody = "Attachment added successfully";

        handleAlert(alertType, alertBody);
      }
    },
    onError(error, variables, context) {},
  });

  const uploadeBannerMutation = useMutation(createNewFile, {
    onSuccess(data) {
      setImageId(data.data.dataValues.id);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const uploadAttachmentMutation = useMutation(createNewFile, {
    onSuccess(data, variables) {
      const file = variables.get("file") as File;

      const attachment = {
        name: file.name,
        fileType: getExtension(file.name),
        size: getFileSize(file.size),
        id: data.data.dataValues.id,
      };

      setAttachments((prev) => [...prev, { ...attachment }]);

      if (isEditMode) {
        submitAttachmentMutation.mutate({
          objectId: params.challengeId!,
          objectType: "CHALLENGE",
          fileId: data.data.dataValues.id,
        });
      }
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  const removeAttachmentMutation = useMutation(removeAttachment, {
    onSuccess() {
      const alertType = ALERT.success;
      const alertBody = "Attachment remove successfully";

      handleAlert(alertType, alertBody);
    },
    onError: (err: ErrorInterface) => {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    },
  });

  // Data Fetching
  const fetchChallenge = async (challengeId: string) => {
    try {
      const challenge = await queryClient.fetchQuery(
        ["challenge", challengeId],
        async () => getChallengeDetails(challengeId),
      );

      setChallenges(challenge.data);
      setImageId(challenge.data.imageId);

      handleIndustryDefaultValue(challenge.data.industryGroupSector);
      handleDisciplineDefaultValue(challenge.data.disciplines);

      fetchChallengeBanner(challenge.data.id);

      fetchAttachments(challenge.data.id);
    } catch (err: any) {
      if (err.response.status === 401) {
        const unauthorized = err.response.status;

        logout(unauthorized);
      }

      const alertType = ALERT.error;
      const alertBody = err.response.data?.message || err.message;

      handleAlert(alertType, alertBody);
    } finally {
      setIsEditMode(true);
      setIsLoading(false);
    }
  };

  const fetchChallengeBanner = async (challengeId: string) => {
    try {
      const challengeBanner = await queryClient.fetchQuery(
        ["challenge-banner", challengeId],
        async () => getChallengeBanner(challengeId),
        { retry: 0 },
      );

      setBannerUrl(challengeBanner);
    } catch (error) {}
  };

  const fetchAttachments = async (objectId: string) => {
    try {
      const attachments = await queryClient.fetchQuery(
        ["challenge-attachments", objectId],
        async () => getAttachments(objectId),
      );

      attachments.data.map((attachment: AttachmentInterface) => {
        (async () => {
          const file = await fetchFile(attachment.fileId);

          const next: FileInterface = {
            name: attachment.fileName,
            fileType: getExtension(attachment.fileName),
            size: getFileSize(file?.size!),
            id: attachment.id,
          };

          setAttachments((prev) => [...prev, { ...next }]);
        })();
      });
    } catch (error) {}
  };

  const fetchFile = async (fileId: string) => {
    try {
      const file = await queryClient.fetchQuery(["file", fileId], async () =>
        getFile(fileId),
      );

      return file;
    } catch (error) {}
  };

  useEffect(() => {
    const setFormValues = async (challengeId: string) => {
      await fetchChallenge(challengeId);
    };

    !params.challengeId
      ? setIsLoading(false)
      : setFormValues(params.challengeId);
  }, []);

  const handleBack = (formIsValid: boolean) => {
    if (activeStep === 0) {
      navigate("/challenges");
    } else {
      if (!formIsValid) {
        snackbar(ALERT.error, "Validation error");
      } else {
        setActiveStep(0);
      }
    }
  };

  const criteriaPercentageError = (
    criteriaValues: ChallengeCriteriaForm[],
  ): boolean => {
    const sum = criteriaValues
      .map(({ percentage }) => {
        if (typeof percentage === "string") return parseInt(percentage);

        return percentage;
      })
      .reduce((prev, next) => prev + next);

    if (sum !== 100) {
      return true;
    }

    return false;
  };

  return challenges?.state === challengeStates.published ? (
    <Navigate
      to="/user/challenges"
      state={{
        showAlert: true,
        alertType: ALERT.error,
        alertBody: "Published challenge not editable",
      }}
      replace
    />
  ) : (
    <>
      <ContentHeader
        h1={params.challengeId ? "Edit Challenge" : "Open new challenge"}
      />

      {isLoading ? (
        <Spinner marginTop />
      ) : (
        <CommonCard title="Challenge Submission Form">
          <Formik
            initialValues={
              {
                ...ChallengeInitialValue,
                ...challenges,
              } as ChallengeForm
            }
            validationSchema={ChallengeSchema}
            onSubmit={handleSubmitForm}
            validateOnMount={true}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                autoComplete="off"
                className="flex flex-col"
              >
                {activeStep === 0 ? (
                  <>
                    <label htmlFor="isPublic" className="text">
                      Challenge visibility
                      <span className="text-orange-alt">*</span>
                    </label>
                    <div className="mt-3 inline-flex items-center gap-4">
                      <Switch
                        id="isPublic"
                        name="isPublic"
                        size="small"
                        checked={props.values.isPublic}
                        onChange={props.handleChange}
                      />
                      <div className="flex flex-col gap-[2px]">
                        <p className="text">
                          {props.values.isPublic ? "Public" : "Pavilion only"}
                        </p>
                        <p className="micro-copy flex flex-row items-center gap-1 text-[12px]">
                          <HtmlTooltip
                            placement="right"
                            title={
                              <>
                                <p>
                                  <b>Public</b> : Chalenge will be posted under{" "}
                                  <u>Open Challenges</u> section
                                </p>
                                <p>
                                  <b>Pavilion only</b> : Chalenge will be posted
                                  under <u>Pavilions</u> section based on user
                                  country
                                </p>
                              </>
                            }
                          >
                            <HiInformationCircle className="cursor-pointer text-lg" />
                          </HtmlTooltip>
                          Set your challenge visibility
                        </p>
                      </div>
                    </div>

                    <label htmlFor="title" className="text mt-4">
                      Challenge title<span className="text-orange-alt">*</span>
                    </label>
                    <TextField
                      value={props.values.title}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.errors.title && props.touched.title ? true : false
                      }
                      helperText={
                        props.errors.title && props.touched.title
                          ? props.errors.title
                          : ""
                      }
                      variant="standard"
                      id="title"
                      name="title"
                      type="text"
                      placeholder="Provide title for the challenge"
                      className="mt-4"
                    />

                    <div className="mt-4 flex flex-col justify-between gap-4 lg:flex-row">
                      <div className="flex basis-1/3 flex-col">
                        <label htmlFor="" className="text mb-2">
                          Challenge banner
                          <span className="text-orange-alt">*</span>
                        </label>

                        <Dropzone
                          multiple={false}
                          maxSize={2000000}
                          accept={{ "image/*": [] }}
                          onDrop={onDropBanner}
                          onDropRejected={onDropRejected}
                        >
                          <div
                            className="text-hint flex h-[139px] cursor-pointer flex-col items-center justify-center rounded border border-slate-300 bg-cover bg-center hover:text-blue-light"
                            style={{ backgroundImage: `url(${bannerUrl}` }}
                          >
                            {bannerUrl ? (
                              <IconButton className="text-transparent hover:border-4 hover:border-solid hover:border-inherit hover:bg-slate-footer hover:text-slate-500">
                                <AiOutlineEdit className="text-3xl" />
                              </IconButton>
                            ) : (
                              <>
                                <AiOutlineCloudUpload className="text-3xl" />
                                <p>Drop image here</p>
                                <p>Supported jpg and png. Max 2048 KB</p>
                              </>
                            )}
                          </div>
                        </Dropzone>
                      </div>
                      <div className="flex basis-2/3 flex-col">
                        <label htmlFor="overview" className="text">
                          Challenge overview
                          <span className="text-orange-alt">*</span>
                        </label>
                        <TextField
                          value={props.values.overview}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.errors.overview && props.touched.overview
                              ? true
                              : false
                          }
                          helperText={
                            props.errors.overview && props.touched.overview
                              ? props.errors.overview
                              : ""
                          }
                          id="overview"
                          name="overview"
                          multiline
                          rows={6}
                          variant="standard"
                          placeholder="Enter your challenge overview briefly"
                        />
                      </div>
                    </div>
                    <label htmlFor="details" className="text mt-4">
                      Challenge details
                      <span className="text-orange-alt">*</span>
                    </label>
                    <div className="mt-1 rounded border border-shadow-text">
                      <Editor
                        setContents={
                          props.values.details ?? challenges?.details
                        }
                        onChange={(context) => {
                          props.setFieldValue("details", context);
                        }}
                      />
                    </div>
                    {props.errors.details && props.touched.details && (
                      <FormHelperText error>
                        {props.errors.details}
                      </FormHelperText>
                    )}

                    <div className="mt-4 flex flex-row justify-between gap-4">
                      <div className="flex w-full flex-col">
                        <label htmlFor="awardAmount" className="text">
                          Specify the challenge reward
                          <span className="text-orange-alt">*</span>
                        </label>
                        <Input
                          value={props.values.awardAmount}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          error={
                            props.errors.awardAmount &&
                            props.touched.awardAmount
                              ? true
                              : false
                          }
                          id="awardAmount"
                          name="awardAmount"
                          type="number"
                          className="mt-4"
                          startAdornment={
                            <InputAdornment position="start">
                              <span className="text-black-text">(US)</span>
                              <span className="ml-1 text-[20px] text-black-text">
                                $
                              </span>
                            </InputAdornment>
                          }
                        />
                        {props.errors.awardAmount &&
                          props.touched.awardAmount && (
                            <FormHelperText error>
                              {props.errors.awardAmount}
                            </FormHelperText>
                          )}
                      </div>
                      <div className="flex w-full flex-col">
                        <label htmlFor="endDate" className="text">
                          Specify latest submission date
                          <span className="text-orange-alt">*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <MobileDatePicker
                            inputFormat="MM/DD/yyyy"
                            value={props.values.endDate}
                            onChange={(value) => {
                              props.setFieldValue("endDate", value);
                            }}
                            disablePast
                            renderInput={(params) => (
                              <>
                                <TextField
                                  variant="standard"
                                  className="mt-4"
                                  {...params}
                                />
                              </>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                    <label htmlFor="industryGroupSector" className="text mt-4">
                      Industry group<span className="text-orange-alt">*</span>
                    </label>
                    <p className="text-hint">
                      Select the Industry 4.0 categories that best represents
                      your Challenge
                    </p>
                    <Autocomplete
                      onChange={(e, arrays) => {
                        props.setFieldValue(
                          "industryGroupSector",
                          industriesToString(arrays),
                        );

                        setIndustryValue(arrays);
                      }}
                      onBlur={props.handleBlur}
                      multiple
                      id="industryGroupSector"
                      value={industryValue}
                      options={industryOptions}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                      className="mt-4"
                    />
                    {props.errors.industryGroupSector &&
                      props.touched.industryGroupSector && (
                        <FormHelperText error>
                          {props.errors.industryGroupSector}
                        </FormHelperText>
                      )}

                    <label htmlFor="disciplines" className="text mt-4">
                      Disciplines<span className="text-orange-alt">*</span>
                    </label>
                    <p className="text-hint">
                      Select the Manufacturing sector that best represents your
                      business/domain
                    </p>
                    <Autocomplete
                      id="disciplines"
                      multiple
                      onBlur={props.handleBlur}
                      onChange={(event, arrays) => {
                        props.setFieldValue(
                          "disciplines",
                          disciplinesToString(arrays),
                        );

                        setDisciplinesValue(arrays);
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.title,
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      value={disciplinesValue}
                      options={disciplineOptions}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.title}</li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                      className="mt-4"
                    />
                    {props.errors.disciplines && props.touched.disciplines && (
                      <FormHelperText error>
                        {props.errors.disciplines}
                      </FormHelperText>
                    )}

                    <label
                      htmlFor="specificTermsAndConditions"
                      className="text mt-4"
                    >
                      Custom terms and conditions
                      <span className="text-orange-alt">*</span>
                    </label>
                    <p className="text-hint">
                      You can edit below terms and conditions to match your
                      requirement
                    </p>
                    <div className="mt-2 rounded border border-shadow-text">
                      <Editor
                        setContents={
                          challenges?.specificTermsAndConditions ??
                          props.values.specificTermsAndConditions !== ""
                            ? props.values.specificTermsAndConditions
                            : defaultTAC
                        }
                        onChange={(context) => {
                          props.setFieldValue(
                            "specificTermsAndConditions",
                            context,
                          );
                        }}
                      />
                    </div>
                    {props.errors.specificTermsAndConditions &&
                      props.touched.specificTermsAndConditions && (
                        <FormHelperText error>
                          {props.errors.specificTermsAndConditions}
                        </FormHelperText>
                      )}

                    <label htmlFor="" className="text mt-4">
                      Supporting document
                    </label>

                    <div className="mt-2 flex h-fit flex-col rounded-[3px] border border-slate-300 p-3">
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        {attachments.map((file) => (
                          <div
                            key={file.id}
                            className="flex flex-row items-center justify-between rounded border border-slate-200 p-2 pr-5"
                          >
                            <div className="flex flex-row justify-start gap-3">
                              <div className="micro-copy rounded bg-slate-400 p-3 text-black-text shadow">
                                {file.fileType}
                              </div>
                              <div className="flex flex-col">
                                <p className="heading-card-title text-black-text">
                                  {formatFileName(file.name)}
                                </p>
                                <span className="micro-copy text-shadow-text">
                                  {file.size}
                                </span>
                              </div>
                            </div>
                            <BiTrash
                              onClick={() => {
                                removeSelectedAttachment(file.id);
                              }}
                              className="cursor-pointer text-xl hover:text-red-alert"
                            />
                          </div>
                        ))}

                        <Dropzone
                          multiple={true}
                          maxSize={10000000}
                          onDrop={onDropFile}
                          onDropRejected={onDropRejected}
                        >
                          <div className="flex cursor-pointer flex-row items-center justify-between rounded border-2 border-dashed border-shadow-text p-2 pr-5 shadow hover:border-blue-light hover:text-blue-light">
                            <div className="flex flex-row items-center justify-start gap-3">
                              <div className="micro-copy rounded bg-slate-400 p-3 text-black-text shadow">
                                pdf
                              </div>
                              <div className="flex flex-col">
                                <p className="heading-card-title text-black-text">
                                  Drop files here
                                </p>
                                <span className="micro-copy text-shadow-text">
                                  Supported files: docx, pptx, pdf, jpg or png,
                                  maximum file size is 10 MB.
                                </span>
                              </div>
                            </div>
                            <AiOutlineCloudUpload className="text-2xl" />
                          </div>
                        </Dropzone>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      htmlFor="challengeEvaluvationCriteria"
                      className="text"
                    >
                      Challenge Evaluvation Criteria
                      <span className="text-orange-alt">*</span>
                    </label>
                    <p className="text-hint">
                      Total must be{" "}
                      <span className="text-blue-mid">equal to 100%</span>
                    </p>
                    <div className="mt-7"></div>

                    <div className="grid grid-cols-4 items-center gap-x-10 gap-y-5">
                      <div className="col-span-3">Criteria</div>
                      <div>Percentage</div>

                      <FieldArray name="criterias">
                        {(arrayHelpers) => (
                          <>
                            {props.values.criterias &&
                              props.values.criterias.length > 0 && (
                                <>
                                  {props.values.criterias.map(
                                    (criteria, index) => (
                                      <Fragment key={index}>
                                        <div className="col-span-3">
                                          <TextField
                                            value={
                                              props.values.criterias[index]
                                                .label
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            error={
                                              props.errors.criterias &&
                                              props.errors.criterias[index] &&
                                              props.touched.criterias &&
                                              props.touched.criterias[index]
                                                ? true
                                                : false
                                            }
                                            variant="standard"
                                            id={`criterias.${index}.label`}
                                            name={`criterias.${index}.label`}
                                            type="text"
                                            placeholder="e.g Criteria"
                                            className="w-full"
                                          />
                                          <ErrorMessage
                                            name={`criterias.${index}.label`}
                                          />
                                        </div>
                                        <div className="flex items-end justify-between gap-x-5">
                                          <TextField
                                            value={
                                              props.values.criterias[index]
                                                .percentage
                                            }
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            variant="standard"
                                            id={`criterias.${index}.percentage`}
                                            name={`criterias.${index}.percentage`}
                                            type="text"
                                            placeholder="1-100 %"
                                            className="w-full"
                                          />
                                          <ErrorMessage
                                            name={`criterias.${index}.percentage`}
                                          />

                                          {index > 2 && (
                                            <RiDeleteBin6Fill
                                              className="cursor-pointer text-red-600"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            />
                                          )}
                                        </div>
                                      </Fragment>
                                    ),
                                  )}

                                  {props.values.criterias &&
                                    props.values.criterias.length < 5 && (
                                      <div className="col-span-4">
                                        <button
                                          className="min-w-full cursor-pointer rounded border border-gray-300 bg-transparent py-1"
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              label: "",
                                              percentage: 0,
                                            })
                                          }
                                        >
                                          Add More Criteria
                                        </button>
                                      </div>
                                    )}
                                </>
                              )}
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </>
                )}

                <div className="mt-8 flex flex-row items-center justify-between ">
                  <div>
                    {challenges?.state === challengeStates.published ? (
                      <></>
                    ) : challenges?.state ===
                      challengeStates.pendingApproval ? (
                      <Button
                        disabled={props.isSubmitting}
                        onClick={() => {
                          props.setFieldValue(
                            "state",
                            challengeStates.pendingApproval,
                          );

                          handleValidation(props.isValid);
                        }}
                        type="submit"
                        className="btn btn-primary disabled:text-white disabled:opacity-75"
                      >
                        {activeStep === 0 ? "Next Step" : "Update challenge"}
                      </Button>
                    ) : (
                      <div className="flex gap-x-5">
                        <Button
                          disabled={props.isSubmitting}
                          onClick={() => {
                            props.setFieldValue("state", challengeStates.draft);

                            handleValidation(props.isValid);
                          }}
                          type="submit"
                          className="btn btn-primary-outline"
                        >
                          Save as Draft
                        </Button>
                        <Button
                          disabled={props.isSubmitting}
                          onClick={() => {
                            props.setFieldValue(
                              "state",
                              challengeStates.pendingApproval,
                            );

                            handleValidation(props.isValid);
                          }}
                          type="submit"
                          className="btn btn-primary disabled:text-white disabled:opacity-75"
                        >
                          {activeStep === 0
                            ? "Next Step"
                            : "Submit for Approval"}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => handleBack(props.isValid)}
                    className="link cursor-pointer no-underline"
                  >
                    Go back
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </CommonCard>
      )}

      <XSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        alertType={alertType as AlertColor}
        alertBody={alertBody}
      />
    </>
  );
};

export default AddChallenge;
