// System
import { useEffect } from "react";

// Modules
import { AiOutlineMenu } from "react-icons/ai";
import { Avatar, Badge } from "@mui/material";
import { BsBell } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";

// API
import { getUserNotifications } from "../../api/notification";
import { getUserDetails, getUserPicture } from "../../api/user";

// Contexts
import { useAuthContext } from "../../contexts/AuthContextProvider";
import { useStateContext } from "../../contexts/ContextProvider";

// Components
import { Notification, UserProfile } from "../../components";

// Utils
import { stringAvatar } from "../../utils/avatar";

// Types
import { AuthContextType } from "../../@types/authContext";
import { ContextType } from "../../@types/context";

type NavButtonProps = {
  title: string;
  customFunc: () => void;
  color: string;
  icon: JSX.Element;
  badgeContent?: number;
};

// Constants
const NavButton = ({
  title,
  customFunc,
  icon,
  color,
  badgeContent,
}: NavButtonProps) => (
  <button
    type="button"
    onClick={() => customFunc()}
    style={{ color }}
    className="relative cursor-pointer bg-white py-2 text-xl"
  >
    <Badge badgeContent={badgeContent} color="error" overlap="circular">
      {icon}
    </Badge>
  </button>
);

const Navbar = () => {
  // Contexts
  const { auth } = useAuthContext() as AuthContextType;
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    setScreenSize,
    handleClick,
    isClicked,
  } = useStateContext() as ContextType;

  // Data Fetching
  const notifications = useQuery(
    ["notifications", auth?.id, { isRead: false }],
    () => getUserNotifications({ userId: auth?.id!, isRead: false }),
  );

  const user = useQuery(["user", auth?.id], () => getUserDetails(auth?.id!));

  const userPicture = useQuery(
    ["user-picture", auth?.id],
    () => getUserPicture(auth?.id!),
    { refetchOnWindowFocus: false, retry: 0 },
  );

  // Effects
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize !== undefined) {
      if (screenSize <= 900) {
        setActiveMenu(false);
      } else {
        setActiveMenu(true);
      }
    }
  }, [screenSize]);

  // JSX
  return (
    <div className="relative mx-6 flex h-[72px] justify-between p-2">
      <NavButton
        title="Menu"
        customFunc={() => setActiveMenu(!activeMenu)}
        color="#323A46"
        icon={<AiOutlineMenu className="text-[24px]" />}
      />
      <div className="flex gap-2">
        <div className="mr-4 flex items-center gap-2 rounded-lg py-2">
          <div className="mr-2 flex w-fit flex-col text-right">
            <span className="text-[13px] font-normal leading-[20px] text-black">
              Hi {user.data?.data.firstName}
            </span>
            <span className="text-[12px] font-normal leading-[15px] text-[#787272]">
              Welcome back!
            </span>
          </div>
          <Avatar
            src={userPicture.data!}
            {...stringAvatar(
              `${user.data?.data.firstName} ${user.data?.data.lastName}`,
            )}
            className="h-[34px] w-[34px] cursor-pointer text-sm"
            onClick={() => handleClick("userProfile", !isClicked.userProfile)}
          />
        </div>
        <NavButton
          title="Notification"
          customFunc={() =>
            handleClick("notification", !isClicked.notification)
          }
          color="#323A46"
          badgeContent={notifications.data?.data.length}
          icon={<BsBell className="text-[24px]" />}
        />

        {isClicked.notification && (
          <Notification datas={notifications.data?.data} />
        )}
        {isClicked.userProfile && (
          <UserProfile datas={user.data?.data!} imageUrl={userPicture.data} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
