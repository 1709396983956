// System
import { useEffect } from "react";

// Libraries
import { AlertColor, Button, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { useMutation } from "@tanstack/react-query";

// API
import { signIn } from "../../../api/authentification";

// Components
import { XSnackbar } from "../../../components";

// Contexts
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

// Custom Hooks
import useSnackbar from "../../../hooks/useSnackbar";

// Schemas
import { SignInForm, SignInInitialValue, SignInSchema } from "./consts/schemas";

// Constants
import { ALERT } from "../../../@consts/alert";

// Types
import { AuthContextType, AuthPropertyType } from "../../../@types/authContext";
import { ErrorInterface } from "../../../@types/response";
import { SnackbarContextType } from "../../../@types/snackbarContext";

type LocationState = {
  from?: {
    pathname: string;
  };
  newUser?: boolean;
  showAlert?: boolean;
  alertType?: string;
  alertBody?: string;
};

const SignIn = () => {
  // Libraries
  const navigate = useNavigate();
  const location = useLocation();

  // Constants
  const from =
    (location.state as LocationState)?.from?.pathname || "/dashboard";

  // Contexts
  const { setAuth } = useAuthContext() as AuthContextType;
  const { openSnackbar, setOpenSnackbar, alertType, alertBody } =
    useSnackbarContext() as SnackbarContextType;

  // Hooks
  const snackbar = useSnackbar();

  // Events
  const handleNewUser = (newUser: boolean) => {
    if (!newUser) return;

    snackbar(ALERT.success, (location.state as LocationState)?.alertBody!);
  };

  const handleValidation = (formIsValid: boolean) => {
    if (!formIsValid) {
      snackbar(ALERT.error, "Validation error");
    }
  };

  const handleFailure = (alertBody: string) => {
    snackbar(ALERT.error, alertBody);
  };

  const handleAlert = (showAlert: boolean) => {
    if (!showAlert) return;

    snackbar(
      (location.state as LocationState)?.alertType!,
      (location.state as LocationState)?.alertBody!,
    );
  };

  // Form Submit
  const onSubmit = (values: SignInForm, actions: FormikHelpers<SignInForm>) => {
    signInMutation.mutate(values);

    actions.setSubmitting(false);
  };

  // Mutation
  const signInMutation = useMutation(signIn, {
    onSuccess: (data) => {
      // handle authentification
      localStorage.setItem("userId", data.data.id);
      localStorage.setItem("accessToken", data.data.token);

      const auth: AuthPropertyType = {
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        role: data.data.role,
        country: data.data.country,
        id: data.data.id,
        accessToken: data.data.token,
      };
      setAuth(auth);

      //redirect
      navigate(from, { replace: true });
    },
    onError: (error: ErrorInterface) => {
      const alertBody = error.response.data?.message || error.message;

      handleFailure(alertBody);
    },
  });

  // Effects
  useEffect(() => {
    handleNewUser((location.state as LocationState)?.newUser!);
    handleAlert((location.state as LocationState)?.showAlert!);
  }, []);

  // JSX
  return (
    <>
      <h1>Sign in</h1>
      <p className="heading-card-title mt-2">
        Don’t have an account yet?{" "}
        <Link to={"/register"} className="text-blue-mid no-underline">
          Sign up
        </Link>
      </p>
      <div className="mt-8">
        <Formik
          initialValues={SignInInitialValue}
          validationSchema={SignInSchema}
          onSubmit={onSubmit}
          validateOnMount={true}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              autoComplete="off"
              className="flex flex-col"
            >
              <label htmlFor="userName" className="text">
                Username<span className="text-orange-alt">*</span>
              </label>
              <TextField
                value={props.values.userName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.userName && props.touched.userName ? true : false
                }
                helperText={
                  props.errors.userName && props.touched.userName
                    ? props.errors.userName
                    : ""
                }
                variant="standard"
                type="text"
                name="userName"
                id="userName"
                placeholder="Enter your username"
                className="mt-4"
              />

              <label htmlFor="password" className="text mt-4">
                Password<span className="text-orange-alt">*</span>
              </label>
              <TextField
                value={props.values.password}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.errors.password && props.touched.password ? true : false
                }
                helperText={
                  props.errors.password && props.touched.password
                    ? props.errors.password
                    : ""
                }
                variant="standard"
                type="password"
                id="password"
                placeholder="Enter your password"
                className="mt-4"
              />

              <div className="mt-8 flex flex-row justify-between">
                <Button
                  disabled={props.isSubmitting}
                  type="submit"
                  onClick={() => handleValidation(props.isValid)}
                  className="btn btn-primary"
                >
                  Sign in
                </Button>

                {/* <Link
                  to={"/forgot-password"}
                  className="no-underline font-semibold text-blue-mid"
                >
                  Forgot password?
                </Link> */}
              </div>
            </form>
          )}
        </Formik>
      </div>

      {openSnackbar && (
        <XSnackbar
          open={openSnackbar}
          onClose={() => {
            setOpenSnackbar(false);
          }}
          alertType={alertType as AlertColor}
          alertBody={alertBody}
        />
      )}
    </>
  );
};

export default SignIn;
